<template>
  <div class="grid grid-cols-4 gap-x-16">
    <a-form-item :label="$t('identity_object.form.Province')" name="provinceName">
      <cds-select
        ref="province"
        :options="listProvince"
        :labelProp="'name'"
        :valueProp="'id'"
        :allowClear="true"
        v-model:value="provinceValue"
        @change="handleChangeProvince"
      >
      </cds-select>
    </a-form-item>

    <a-form-item :label="$t('identity_object.form.District')" name="districtName">
      <cds-select
        ref="district"
        :options="listDistrict"
        :labelProp="'name'"
        :valueProp="'id'"
        :allowClear="true"
        v-model:value="districtValue"
        @change="handleChangeDistrict"
      >
      </cds-select>
    </a-form-item>

    <a-form-item :label="$t('identity_object.form.Ward')" name="wardName">
      <cds-select
        ref="district"
        :options="listWard"
        :labelProp="'name'"
        :valueProp="'id'"
        :allowClear="true"
        v-model:value="wardValue"
        @change="handleChangeWard"
      >
      </cds-select>
    </a-form-item>

    <a-form-item :label="$t('identity_object.form.Other')" name="addressDetail">
      <cds-input
        class="w-full ant-input-sm"
        v-model:value="addressValue"
        show-count
        size="medium"
        :placeholder="$t('identity_object.form.Other')"
        @change="handleChangeAddress"
      />
    </a-form-item>
  </div>
</template>
<script setup>
import { ref, reactive, computed, onMounted } from 'vue';
import { useStore } from 'vuex';
const emit = defineEmits(["update:address", "update:province", "update:district", "update:ward"]);
const props = defineProps({
  address: {
    type: String,
    required: true,
  },
  province: {
    type: Number,
    required: true,
  },
  district: {
    type: Number,
    required: true,
  },
  ward: {
    type: Number,
    required: true,
  },
});

const { state } = useStore();
let addressValue = ref(props.address);
let provinceValue = ref(props.province);
let districtValue = ref(props.district);
let wardValue = ref(props.ward);
let listDistrict = computed(() => {
  const province = (listProvince.value || []).find(
    (e) => e.id === provinceValue.value
  );
  if (province) return province.districtsData;
  return [];
});
let listWard = computed(() => {
  const district = (listDistrict.value || []).find(
    (e) => e.id === districtValue.value
  );
  if (district) return district.wardsData;
  return [];
});
const listProvince = computed(() => state.location.locations || []);

const handleChangeAddress = (val) => {
  emit('update:address', val.target.value);
};
const handleChangeProvince = (value) => {
  emit('update:district', null);
  emit('update:ward', null);
  districtValue.value = null;
  wardValue.value = null;
  emit('update:province', value);
};
const handleChangeDistrict = (value) => {
  wardValue.value = null;
  emit('update:ward', null);
  emit('update:district', value);
};
const handleChangeWard = (value) => {
  if (!value) {
    emit('update:ward', null);
  } else {
    emit('update:ward', value);
  }
};
</script>
