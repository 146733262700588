<template>
  <AutoCompleteStyled
    @select="onSelect"
    @search="onSearching"
    :placeholder="placeholder"
    :options="options"
    :value="valueInput"
  >
  </AutoCompleteStyled>
</template>

<script>
import { AutoCompleteStyled } from './style';
import VueTypes from 'vue-types';
import {toRefs, ref, computed, defineComponent, watch} from 'vue';
import { useStore } from 'vuex';

export default defineComponent({
  name: 'AutoComplete',
  components: {
    AutoCompleteStyled,
  },
  props: {
    value: VueTypes.string.def(''),
    customComponent: VueTypes.bool.def(false),
    patterns: VueTypes.bool.def(false),
    patternButtons: VueTypes.bool.def(false),
    dataSource: VueTypes.array,
    placeholder: VueTypes.string,
  },
  setup(props, {emit}) {
    const { dataSource } = toRefs(props);
    const { state } = useStore();
    const value = ref('');
    const rtl = computed(() => state.themeLayout.rtlData);
    let valueInput = ref(props.value);
    let options = ref(props.dataSource)

    const onSearching = (searchText) => {
      console.log(dataSource.value)
      valueInput.value = searchText;
      options.value = !searchText ? dataSource.value : dataSource.value.filter((item) => item.text.toUpperCase().startsWith(searchText.toUpperCase()));
    };

    watch(() => props.value, (val) => valueInput.value = val)

    watch(() => dataSource.value, (val) => {
      if(options.value.length === 0) options.value = val
    })

    watch(() => valueInput.value, (val) => emit('update:value', val))

    const onSelect = (e) => {
      valueInput.value = e;
    };

    return {
      value,
      rtl,
      onSearching,
      onSelect,
      valueInput,
      options
    };
  },
});
</script>
