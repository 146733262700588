<template>
  <div class="">
    <p>{{ formData }}</p>
    <a-form ref="formVehicle" :model="formData" :rules="rules" :label-col="{ span: 24 }" label-align="top"
      :validate-trigger="['change', 'blur']">
      <div class="flex items-center justify-between">
        <a-form-item class="custom-form" label="Avatar" name="images">
          <cds-upload accept=".jpg,.png,.jpeg" :isMul="true" v-model:value="formData.avatar"
            :root-path="ROOT_PATH_MINIO.IDENTITY_VEHICLE" />
        </a-form-item>

        <div class="flex items-center">
          <a-form-item class="custom-form" :label="$t('identity_object.form.Front Card')" name="images">
            <cds-upload accept=".jpg,.png,.jpeg" :isMul="false" v-model:value="formData.vehicleCards.frontCard"
              :root-path="ROOT_PATH_MINIO.IDENTITY_VEHICLE_CARD" />
          </a-form-item>
          <a-form-item class="custom-form" :label="$t('identity_object.form.Back Card')" name="images">
            <cds-upload accept=".jpg,.png,.jpeg" :isMul="false" v-model:value="formData.vehicleCards.backCard"
              :root-path="ROOT_PATH_MINIO.IDENTITY_VEHICLE_CARD" />
          </a-form-item>
        </div>
      </div>
      <div class="grid grid-cols-3 gap-x-16">
        <a-form-item :label="$t('identity_object.vehicle.Vehicle Type')" name="vehicleType">
          <cds-select :options="vehicleTypes" :labelProp="'name'" :valueProp="'value'" :allowClear="true"
            v-model:value="formData.vehicleType">
          </cds-select>
        </a-form-item>
        <a-form-item :label="$t('identity_object.vehicle.License Plate')" name="licensePlate">
          <cds-input class="w-full ant-input-sm" v-model:value="formData.licensePlate" :maxlength="15" show-count
            size="medium" :placeholder="$t('identity_object.vehicle.License Plate')" />
        </a-form-item>
        <a-form-item :label="$t('identity_object.vehicle.Vehicle Color')" name="['color']">
          <cds-select :options="vehicleColors" :labelProp="'name'" :valueProp="'value'" :allowClear="true"
            v-model:value="formData.color">
          </cds-select>
        </a-form-item>
        <a-form-item :label="$t('identity_object.vehicle.Vehcle Brand')" name="brand">
          <cds-select :options="vehicleBrands" :labelProp="'name'" :valueProp="'value'" :allowClear="true"
            v-model:value="formData.vehicleCards.brandId">
          </cds-select>
        </a-form-item>
        <a-form-item :label="$t('identity_object.vehicle.Relationship')" name="relationship">
          <cds-select :options="RELATIONSHIP_PERSON_VEHICLE_ARR" :labelProp="'label'" :valueProp="'value'"
            :allowClear="true" v-model:value="formData.relationship">
          </cds-select>
        </a-form-item>
        <a-form-item :label="$t('identity_object.vehicle.Person')" name="personId">
          <!-- <cds-select :options="RELATIONSHIP_PERSON_VEHICLE_ARR" :labelProp="'label'" :valueProp="'value'" :allowClear="true"
            v-model:value="formData.personId">
          </cds-select> -->
          <a-select v-model:value="formData.personId" show-search placeholder="input search text" class="w-full"
            :default-active-first-option="false" :show-arrow="true" :filter-option="false" 
            :options="listPersons.map((item) => ({
              label: item.name,
              value: item.id,
            }))" @search="handleSearch" @change="handleChange"></a-select>
        </a-form-item>
        <a-form-item :label="$t('identity_object.vehicle.Card Type')" name="type">
          <cds-select :options="vehicleTypes" :labelProp="'name'" :valueProp="'value'" :allowClear="true"
            v-model:value="formData.vehicleCards.type">
          </cds-select>
        </a-form-item>
        <a-form-item :label="$t('identity_object.vehicle.Card Code')" name="cardCode">
          <cds-input class="w-full ant-input-sm" v-model:value="formData.vehicleCards.code" :maxlength="50" show-count
            size="medium" :placeholder="$t('identity_object.vehicle.Card Code')" />
        </a-form-item>
        <a-form-item :label="$t('identity_object.vehicle.Card Vehicle Color')" name="[vehicleCards, 'color']">
          <cds-select :options="vehicleColors" :labelProp="'name'" :valueProp="'value'" :allowClear="true"
            v-model:value="formData.vehicleCards.color">
          </cds-select>
        </a-form-item>
      </div>

      <div class="">
        <h2 class="font-bold text-xl">
          {{ $t('identity_object.form.Pemanent Resident') }}
        </h2>
        <form-location v-model:address="formData.vehicleCards.address.addressDetail"
          v-model:province="formData.vehicleCards.address.provinceId"
          v-model:district="formData.vehicleCards.address.districtId"
          v-model:ward="formData.vehicleCards.address.wardId" />
      </div>

      <a-form-item :label="$t('identity_object.form.Group')">
        <cds-tree-select :checkStrictly="true" :tree-data="treeGroup" :field-names="fieldNames"
          v-model:value="formData.group" />
      </a-form-item>

      <div class="flex items-center gap-x-4 justify-end">
        <cds-button @click="onSubmit" key="submit" type="primary" size="small" type-html="submit">
          <cds-feather-icons class="mr-10" type="save" />
          {{
            formMode === FORM_MODE.UPDATE
            ? $t('common.update')
            : $t('common.save')
          }}
        </cds-button>
        <router-link to="/identity/object">
          <cds-button type="dark" size="small">
            {{ $t('common.cancel') }}
          </cds-button>
        </router-link>
        <img v-if="formMode === FORM_MODE.UPDATE" @click="handleDelete()" src="@/static/img/icon/trash.svg" alt="trash"
          class="w-[32px] h-[32px] cursor-pointer" />
      </div>
    </a-form>
  </div>
</template>
<script setup>
import { ref, reactive, computed, onMounted } from 'vue';
import { useStore } from 'vuex';
import { useRouter, useRoute } from 'vue-router';
import FormLocation from './FormLocation.vue';
import CdsInputNumber from '@/components/input-number';
import CdsAutoComplete from '@/components/autoComplete/autoComplete.vue';
import {
  TYPE_IMAGE,
  ROOT_PATH_MINIO,
  FILTER_GROUP_TYPE,
  FILTER_APP_PARAMS,
  FORM_MODE,
  FILTER_SUGGEST_TYPE,
  RELATIONSHIP_PERSON_VEHICLE_ARR
} from '@/config/Constant.js';
import CdsUpload from '@/components/upload';
import ConstantAPI from '@/config/ConstantAPI';
import { DataService } from '@/dataService/dataService';
import { greaterThanCurrent } from '@/util/common-utils';
import { emailRule, maxlength, numberRule, required } from '@/util/validation';

const props = defineProps({
  vehicle: {
    type: Object,
    required: true,
  },
  formMode: {
    type: String,
    required: true,
  },
});
console.log(props.vehicle);
const router = useRouter();
const route = useRoute();

const rules = {
  // phone: [numberRule('Phone number'), maxlength(12, 'Phone number')],
  // email: [required('Email'), emailRule('Email')],
};

const formVehicle = ref(null);

const getUrlsType = (data, type) => {
  const urls = [];

  data.forEach((item) => {
    if (item.type === type) {
      urls.push(item.url);
    }
  });

  return urls;
};

const fieldNames = { label: 'name', value: 'id' };
const { state, dispatch } = useStore();

const cardTypes = computed(() => state.person.cardTypes);
const vehicleColors = computed(() => state.vehicle.vehicleColors);
const vehicleTypes = computed(() => state.vehicle.vehicleTypes);
const vehicleBrands = computed(() => state.vehicle.vehicleBrands);
const treeGroup = computed(() => state.ioGroup.treeGroup);
const listPersons = ref([])

const formData = reactive(
  Object.assign(
    {},
    {
      ...props.vehicle,
      vehicleType: props.vehicle.vehicleType.toString(),
      relationship: props.vehicle.identityObjects.length ? props.vehicle.identityObjects.find((item) => item.personId === route.query.idPerson).type : '',
      personId: props.vehicle.identityObjects.length ? props.vehicle.identityObjects.find((item) => item.personId === route.query.idPerson).personId : '',
      avatar: props.vehicle.images.map((item) => item.url),
      group: props.vehicle.ioGroups.map((item) => item.groupId),
      vehicleCards: {
        ...props.vehicle.vehicleCards,
        type: props.vehicle.vehicleCards.type
          ? props.vehicle.vehicleCards.type.toString()
          : null,
        frontCard: getUrlsType(
          props.vehicle.vehicleCards.images,
          TYPE_IMAGE.IDENTITY_VEHICLE_FRONT_CARD
        ),
        backCard: getUrlsType(
          props.vehicle.vehicleCards.images,
          TYPE_IMAGE.IDENTITY_VEHICLE_BACK_CARD
        ),
        address: {
          addressDetail: props.vehicle.vehicleCards.address.addressDetail
            ? props.vehicle.vehicleCards.address.addressDetail
            : '',
          districtId: props.vehicle.vehicleCards.address.districtId
            ? props.vehicle.vehicleCards.address.districtId
            : null,
          provinceId: props.vehicle.vehicleCards.address.provinceId
            ? props.vehicle.vehicleCards.address.provinceId
            : null,
          wardId: props.vehicle.vehicleCards.address.wardId
            ? props.vehicle.vehicleCards.address.wardId
            : null,
        },
      },
    }
  )
);

const handleDelete = async () => {
  let payload = [];
  payload.push({
    id: formData.id,
  });
  const res = await dispatch('vehicle/delete', payload);
  if (res) {
    router.push('/identity/object');
  }
};

const handleSearch = async (val) => {
  await fetchData(val);
};
const handleChange = async (val) => {
  console.log(val);
  value.value = val;
  await fetchData(val);
};

const fetchData = async (val) => {
  const payload = {
    name: val ? val : '',
    page: 0,
    size: 20
  }
  const response = await DataService.callApi(
    ConstantAPI.indentity_management.SEARCH,
    null,
    payload,
    null
  )
  console.log(response);
  listPersons.value = response.data
}

onMounted(() => {
  const payload = {
    type: FILTER_APP_PARAMS.CARD_TYPE,
  };
  const payloadColor = {
    type: FILTER_APP_PARAMS.COLOR,
  };
  const payloadType = {
    type: FILTER_APP_PARAMS.VEHICLE,
  };
  const payloadBrand = {
    type: FILTER_APP_PARAMS.BRAND,
  };
  const payloadGroup = {
    type: FILTER_GROUP_TYPE.IO,
  };
  fetchData();
  dispatch('person/getCardTypes', payload);
  dispatch('vehicle/getVehicleColors', payloadColor);
  dispatch('vehicle/getVehicleTypes', payloadType);
  dispatch('vehicle/getVehicleBrands', payloadBrand);
  dispatch('ioGroup/getTreeGroup', payloadGroup);
});

const onSubmit = () => {
  try {
    formVehicle.value
      .validate()
      .then(async () => {
        // handle relationships
        const temp = formData.identityObjects.findIndex((item) => item.personId === route.query.idPerson)
        // console.log(formData.identityObjects.splice(temp, 1));
        // console.log(temp);
        // console.log(formData.identityObjects[temp]);
        console.log(temp);
        if (!formData.relationship && !formData.personId) {
          console.log('xx');
          formData.identityObjects.splice(temp, 1);
        } else {
          console.log('yy');
          formData.identityObjects[temp].personId = formData.personId
          formData.identityObjects[temp].type = formData.relationship
        }
        console.log(formData.identityObjects);

        const state = {
          vehicleType: Number(formData.vehicleType),
          licensePlate: formData.licensePlate,
          color: formData.color,
          identityObjects: formData.identityObjects,
          images: formData.avatar.map((url) => ({
            url: url,
            type: TYPE_IMAGE.IDENTITY_PERSON_VEHICLE,
            fileName: url && url.substring(url.lastIndexOf('/') + 1),
          })),
          ioGroups: formData.group.map((id) => ({
            groupId: id.value ? id.value : id,
          })),
          vehicleCards: {
            type: Number(formData.vehicleCards.type),
            licensePlate: formData.vehicleCards.licensePlate,
            code: formData.vehicleCards.code,
            brandId: formData.vehicleCards.brandId,
            color: formData.vehicleCards.color,
            address: formData.vehicleCards.address,
            images: [
              {
                url: formData.vehicleCards.frontCard[0],
                fileName:
                  formData.vehicleCards.frontCard[0] &&
                  formData.vehicleCards.frontCard[0].substring(
                    formData.vehicleCards.frontCard[0].lastIndexOf('/') + 1
                  ),
                type: TYPE_IMAGE.IDENTITY_VEHICLE_FRONT_CARD,
              },
              {
                url: formData.vehicleCards.backCard[0],
                fileName:
                  formData.vehicleCards.backCard[0] &&
                  formData.vehicleCards.backCard[0].substring(
                    formData.vehicleCards.backCard[0].lastIndexOf('/') + 1
                  ),
                type: TYPE_IMAGE.IDENTITY_VEHICLE_BACK_CARD,
              },
            ],
          }
        };
        if (props.formMode === FORM_MODE.CREATE) {
          // await dispatch('vehicle/createVehicle', state);
        } else if (props.formMode === FORM_MODE.UPDATE) {
          const payload = {
            ...state,
            id: formData.id,
          };
          console.log(payload);
          await dispatch('vehicle/updateVehicle', payload);
        }
      })
      .catch((error) => {
        console.log('error', error);
      });
  } catch { }
}

</script>
